import request from '../request'

//差旅地址列表
export const travel_address_index_request = p => {
    return request({
        method:'GET',
        url:'travel_address/index',
        params: p
    })
}

//添加差旅地址
export const travel_address_add_request = d => {
    return request({
        method:'POST',
        url:'travel_address/add',
        data: d
    })
}

//删除差旅地址
export const travel_address_del_request = id => {
    return request({
        method:'DELETE',
        url:'travel_address/del',
        data: {
            id: id
        }
    })
}

//修改差旅地址
export const travel_address_edit_request = d => {
    return request({
        method:'PUT',
        url:'travel_address/edit',
        data: {
            id: d.id,
            name: d.name
        }
    })
}

//获取差旅地址详情
export const travel_address_details_request = id => {
    return request({
        method:'GET',
        url:'travel_address/details',
        params: {
            id
        }
    })
}