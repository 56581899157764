<template>
  <el-card class="box-card">
    <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
    <el-form
      ref="searchform"
      :inline="true"
      :model="form"
      @submit.native.prevent
      size="medium"
    >
      <el-form-item label="差旅地址" prop="name">
        <el-input
          v-model="form.name"
          placeholder="地址搜索"
          class="namec"
          @keyup.enter.native="search()"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" plain @click="search">搜索</el-button>
        <el-button type="warning" plain @click="resetbtn('searchform')"
          >重置</el-button
        >
      </el-form-item>
      <el-button
        v-if="$_has(add_auth)"
        type="success"
        plain
        round
        @click="addTeam"
        class="temp_add"
        size="medium"
        >添加地址</el-button
      >
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "TravelAddressSearchBarComponent",
  data() {
    return {
      fdialogFormVisible: false,
      form: {
        name: "",
        page: 1,
      },
    };
  },
  computed: {},
  methods: {
    search() {
      this.$emit("search", this.form);
    },
    resetbtn(f) {
      this.$refs[f].resetFields();
    },
    addTeam() {
      this.$emit("addTeam");
    },
  },
  filters: {},
  props: {
    add_auth: String,
  },
  created() {},
  destoryed() {},
  mounted() {},
  updated() {},
  components: {},
  watch: {},
};
</script>

<style>
.temp_add {
  float: right;
  margin-bottom: 10px;
}
</style>
