<template>
  <div>
    <el-dialog
      :title="dialogFormTitle"
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      width="30%"
      :center="true"
      top="20vh"
      :close-on-click-modal="false"
      :before-close="() => closeTravelAddressDialog()"
      v-loading.fullscreen.lock="login_loading"
    >
      <div>
        <el-form
          :model="TravelAddress"
          :rules="rules"
          ref="TravelAddressEditForm"
          label-width="100px"
          size="small"
        >
        <!-- 添加按钮的弹框1 -->
          <div v-if="show_type">
            <el-form-item label="地址" prop="name">
              <el-input v-model="TravelAddress.name" clearable></el-input>
            </el-form-item>
          </div>
          <!-- 添加成功后详情弹框0 -->
          <div v-else>
            <el-descriptions
              class="margin-top"
              :column="1"
              :labelStyle="{ width: '120px', 'text-align': 'center' }"
              :contentStyle="{ 'text-align': 'center' }"
              border
            >
              <el-descriptions-item>
                <template slot="label">
                  <span>地址</span>
                </template>
                <span>{{TravelAddress.name}}</span>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-form>
      </div>
      <!-- 底部按钮展示 -->
      <div slot="footer" class="dialog-footer">
        <!-- 详情底部按钮0 -->
        <div v-if="show_type === 0">
          <el-button
           v-if="$_has(edit_auth)" 
            type="primary"
            plain
            @click="$emit('show_edit')"
            >修改</el-button
          >
          <el-button
            v-if="$_has(del_auth)"
            type="danger"
            plain
            @click="TravelAddressDel('TravelAddressEditForm')"
            >删除</el-button
          >
          <el-button plain @click="closeTravelAddressDialog()"
            >关闭</el-button
          >
        </div>
        <!-- 添加底部按钮1 -->
        <div v-else-if="show_type === 1">
          <el-button
            type="success"
            plain
            @click="submitInsert('TravelAddressEditForm')"
            >确定</el-button
          >
          <el-button type="warning" plain @click="resetForm()">重置</el-button>
          <el-button plain @click="closeTravelAddressDialog()"
            >关闭</el-button
          >
        </div>
        <!-- 修改底部按钮2 -->
        <div v-else>
          <el-button
            type="success"
            plain
            @click="submitEdit('TravelAddressEditForm')"
            >确定修改</el-button
          >
          <el-button plain @click="closeTravelAddressDialog()"
            >取消操作</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  travel_address_add_request,
  travel_address_del_request,
  travel_address_edit_request,
} from "@/network/finance/TravelAddress.js";

export default {
  name: "TravelAddressEditComponent",
  data() {
    return {
      login_loading: false,
      TravelAddress: {
        name: "",
        id: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "地址名称必填",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    dialogFormTitle() {
      if (this.show_type === 0) {
        return "地址详情";
      } else if (this.show_type === 1) {
        return "添加地址";
      } else {
        return "修改地址";
      }
    },
  },
  methods: {
    closeTravelAddressDialog() {
      this.resetForm();
      this.$emit("exitTravelAddressDialog");
    },
    submitInsert(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.TravelAddressInsert(formName);
      });
    },
    TravelAddressInsert(formName) {
      this.login_loading = true;
      travel_address_add_request(this.TravelAddress)
        .then((s) => {
          this.login_loading = false;
          if (s.status === 0) {
            this.$message.success("添加成功!");
            this.$emit("exitTravelAddressDialog");
            this.$emit("search");
            this.$emit("details_row", s.result.id * 1);
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          this.login_loading = false;
          this.$message.error(err);
        });
    },
    resetForm() {
      this.TravelAddress = {
        name: "",
      };
    },
    submitEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return;
        }
        this.TravelAddressEdit(formName);
      });
    },
    TravelAddressEdit(formName) {
      this.login_loading = true;
      this.TravelAddress.id = this.id;
      travel_address_edit_request(this.TravelAddress)
        .then((s) => {
          this.login_loading = false;
          if (s.status === 0) {
            this.$message.success("修改成功!");
            this.$emit("exitTravelAddressDialog");
            this.$emit("search");
            this.$emit("details_row", this.TravelAddress.id);
          } else {
            this.$message.error(s.msg);
          }
        })
        .catch((err) => {
          this.login_loading = false;
          this.$message.error(err);
        });
    },
    TravelAddressDel(formName) {
      this.$confirm(
        "是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          travel_address_del_request(this.id)
            .then((s) => {
              if (s.status === 0) {
                this.$message.success("删除成功!");
                this.$emit("search");
              } else {
                this.$message.error(s.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            })
            .finally(() => this.closeTravelAddressDialog());
        })
        .catch((err) => {});
    },
  },
  filters: {},
  props: {
    dialogFormVisible: Boolean,
    id: {
      type: Number,
      default() {
        return 0;
      },
    },

    travel_address_details_data: {
      type: Object,
      default() {
        return {};
      },
    },
    show_type: {
      type: Number,
      default() {
        return 0;
      },
    },
    edit_auth: String,
    del_auth: String
  },
  created() {},
  destoryed() {},
  mounted() {},
  updated() {},
  components: {},
  watch: {
    travel_address_details_data(newVal) {
      this.TravelAddress = newVal;
    },
  },
};
</script>

<style lang="less"></style>
